import { FirstLoginCampaignStrategy } from "./types";

export class FirstLoginCampaign {
  public static cookieName: string;

  constructor(private strategy?: FirstLoginCampaignStrategy) {}

  public init(): void {
    try {
      this.strategy.run();
    } catch (error) {
      console.error(error);
    }
  }

  public setStrategy(strategy: FirstLoginCampaignStrategy) {
    this.strategy = strategy;
  }
}

// Safari 16 doesn't like the static field syntax, and is still within our supported browsers.
// We can declare the static _type_ above, for TS reasons, but must assing the value in the
// ways of our ancestors.
FirstLoginCampaign.cookieName = "__decodable_first_login_campaign_completed";
