import { FirstLoginCampaign } from ".";
import { CookieManager } from "../cookieManager";
import { UTM_KEYS } from "./constants";
import { FirstLoginCampaignStrategy, UTMKeys, UtmMap } from "./types";

export class WriteUtmCookiesFromURLSearchParams
  implements FirstLoginCampaignStrategy
{
  private cookieManager = new CookieManager();

  public run() {
    const utmMap = this.getUtmMap(new URLSearchParams(window.location.search));
    const hasAnyUtmParams = Object.values(utmMap).some((v) => !!v);

    if (!hasAnyUtmParams) {
      return;
    }

    UTM_KEYS.forEach((key) => {
      if (utmMap[key]) {
        this.cookieManager.createCookie({
          name: `__decodable_${key}`,
          value: utmMap[key],
          days: 1,
        });
      }
    });
  }

  private getUtmMap(queryParams: URLSearchParams): UtmMap {
    const params = UTM_KEYS.reduce<UtmMap>((acc, curr) => {
      acc[curr] = queryParams.get(curr);
      return acc;
    }, {} as UtmMap);

    return params;
  }
}

type HubSpotFormField = {
  objectTypeId: "0-1";
  name: UTMKeys | "email";
  value: string;
};

export class SendUtmDataToHubSpot implements FirstLoginCampaignStrategy {
  private cookieManager = new CookieManager();

  constructor(private readonly email: string) {}

  run(): void {
    // The user has completed the campaign, but may have refreshed the page.
    if (this.cookieManager.readCookie(FirstLoginCampaign.cookieName)) {
      return;
    }

    const fields = this.getHubSpotFields();

    const headers = new Headers();
    headers.append("content-type", "application/json");

    fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/8792459/af2a1e9a-51b2-430c-a8ac-b40c5f6f5a2f",
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          fields,
          context: {
            pageUri: location.toString(),
            pageName: document.title,
            hutk: this.cookieManager.readCookie("hubspotutk"),
          },
        }),
      }
    )
      .then(() => {
        this.cookieManager.createCookie({
          name: FirstLoginCampaign.cookieName,
          value: new Date().toUTCString(),
          days: 365,
        });
      })
      .catch(console.error);
  }

  private getHubSpotFields() {
    return UTM_KEYS.reduce(
      (acc, curr) => {
        const cookieKey = `__decodable_${curr}`;
        const value = this.cookieManager.readCookie(cookieKey);
        if (value) {
          acc.push({
            objectTypeId: "0-1",
            name: curr,
            value,
          });
          this.cookieManager.eraseCookie(cookieKey);
        }

        return acc;
      },
      [
        {
          objectTypeId: "0-1",
          name: "email",
          value: this.email,
        },
      ] as Array<HubSpotFormField>
    );
  }
}
