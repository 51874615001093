import { FirstLoginCampaign } from "./util/marketingCampaigns/firstLogin";
import { WriteUtmCookiesFromURLSearchParams } from "./util/marketingCampaigns/firstLogin/strategies";

// Tell TypeScript this is a module.
// Needed because we have no toplevel synchronous imports/exports.
export {};

// Check for UTM query params
new FirstLoginCampaign(new WriteUtmCookiesFromURLSearchParams()).init();

// In parallel, bootstrap authentication and load the core app bundle.
// This allows us to perform the token exchange while loading the majority
// of the JS needed to start our client-side routing.
const AppPromise = import("./components/App");
import("./bootstrap").then(({ bootstrap }) => {
  bootstrap(AppPromise);
});
