export class CookieManager {
  public createCookie(args: { name: string; value: string; days: number }) {
    const expires = this.getExpiryDate(args.days);
    const cookie = `${args.name}=${
      args.value
    }; expires=${expires}; domain=${this.getHost()}; path=/; samesite=lax`;
    document.cookie = cookie;
  }

  public readCookie(name: string) {
    const key = `${name}=`;
    const cookies = document.cookie.split(";").map((value) => value.trim());

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const cookieSubstringKeyLength = cookie.substring(0, key.length);
      if (cookieSubstringKeyLength === key) {
        return cookie.substring(key.length);
      }
    }

    return "";
  }

  public eraseCookie(name: string) {
    this.createCookie({ name, value: "", days: -1 });
  }

  private getExpiryDate(days: number) {
    const daysMs = days * 24 * 60 * 60 * 1000;
    const date = new Date();
    date.setTime(date.getTime() + daysMs);
    return date.toUTCString();
  }

  private getHost() {
    const hostname = window.location.hostname.split(".").reverse();
    if (hostname[0] && hostname[1]) {
      return `${hostname[1]}.${hostname[0]}`;
    }
    return "";
  }
}
